import "./Skills.css";
import React, { useEffect } from "react";
import Badge from 'react-bootstrap/Badge';

const Skills: React.FC<{ skills: string[], isSkillsList?: boolean }> = ({ skills, isSkillsList = false }) => {
  var joinedSkills = skills
    .sort((a, b) => (a > b ? 1 : -1)) // alpha sort
    .map(((skill: string) => {
      if (skill === undefined) {
        return;
      }

      return <Badge key={`skill_${skill}_${Math.random()}`} bg="secondary" className="badge">{skill}</Badge>
    }
    ))
    .filter((skill) => skill) // Remove undefined values


  return (
    <div className="skills-container" >
      <div className="skills-bar">New {isSkillsList ? 'Skills' : 'Architecture Patterns'}</div>
      <div className="skills">
        {joinedSkills}
      </div>
    </div>
  );
}

export default Skills;