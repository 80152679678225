import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import App from "./Components/screens/App/App";

// Create a root element and render the app using ReactDOM.createRoot
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// ReactDOM.render(
//   <Router>
//     <App />
//   </Router>,
//   document.getElementById("root")
// );



root.render(
  <Router>
    <App />
  </Router>
);