import Timeline from "../../atoms/Timeline/Timeline";
import "./Main.css";

import { jobs, schools } from "../../../Constants";
import Personal from "../../atoms/Personal/Personal";
import { SlalomProjectDetails } from "../Projects/SlalomProjectsAnchor";
import { MissionStatement } from "../../organisms/MissionStatement/MissionStatement";

export default function Main() {
  return (
    <div className="main container justify-content-center">
      <div className="mission--personal--container">
        <Personal />
        <MissionStatement />
      </div>
      <div className="row">
        <Timeline>  
        {/* Timeline of my education and career experiences */}
          <Timeline.RoleEntry role="Engineer"/>
          <Timeline.JobEntry job={jobs["southwestAirlines"][0]}/>

          <Timeline.RoleEntry role="Architect" />
          <Timeline.JobEntry job={jobs["slalomBuild"][1]} extraDetailsElement={SlalomProjectDetails()} />
          <Timeline.JobEntry job={jobs["slalomBuild"][0]} extraDetailsElement={SlalomProjectDetails()} />
          <Timeline.JobEntry job={jobs["cbord"][1]} />

          <Timeline.RoleEntry role="Tech Lead" />
          <Timeline.JobEntry job={jobs["cbord"][0]} />
          <Timeline.JobEntry job={jobs["lockheedMartin"][0]} />

          <Timeline.RoleEntry role="Engineer" />
          <Timeline.EducationEntry school={schools[1]} />
          <Timeline.JobEntry job={jobs["pier1"][0]} />
          <Timeline.JobEntry job={jobs["bnsf"][0]} />
          <Timeline.EducationEntry school={schools[0]} />
          
          {/* <Timeline.RoleEntry role="Intern" />
          <Timeline.JobEntry job={jobs["rtl"][0]} />
          <Timeline.JobEntry job={jobs["halliburton"][0]} />
          <Timeline.JobEntry job={jobs["motio"][0]} /> */}
        </Timeline>
      </div>
    </div>
  );
}
