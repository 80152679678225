import "./Timeline.css";

import { RoleEntry } from "./RoleEntry/RoleEntry";
import { JobEntry } from "./JobEntry/JobEntry";
import { EducationEntry } from "./EducationEntry/EducationEntry";
import { JSX } from "react";

const Timeline = ({ children }: any) => {
  return (
    <div className="main-timeline">
      {children.map((child: JSX.Element, index: number) => (
        <div className="timeline" key={`timeline_${index}`}>
          {child}
        </div>
      ))}
    </div>
  );
};

Timeline.JobEntry = JobEntry;
Timeline.RoleEntry = RoleEntry;
Timeline.EducationEntry = EducationEntry;

export default Timeline;