import "./Certification.css";

import cloud_practitioner from '../../organisms/Certifications/AWS Cloud Practitioner.png';
import developer_associate from '../../organisms/Certifications/AWS Developer Associate.png';
import sysops_associate from '../../organisms/Certifications/AWS SysOps Admin Associate.png';
import sa_associate from '../../organisms/Certifications/AWS Solutions Architect Associate.png';
import sa_pro from '../../organisms/Certifications/AWS Solutions Architect Professional.png';
import security_specialty from '../../organisms/Certifications/AWS Security Specialty.png';
import React, { useEffect } from "react";


export enum CertificationType {
  CloudPractitioner = "cloud_practitioner",
  DeveloperAssociate = "developer_associate",
  SysOpsAssociate = "sysops_associate",
  SAAssociate = "sa_associate",
  SAPro = "sa_pro",
  SecuritySpecialty = "security_specialty",
}

// let certification_images = [cloud_practitioner, developer_associate, sysops_associate, sa_associate, sa_pro, security_specialty]
// export default function Certifications() {
//   return (
//     <>
//       {certification_images.map((cert, index) => {
//         return <Certification key={`cert${index}`} image={cert} />;
//       })}
//     </>
//   );
// }

function getCertificationImage(certType: CertificationType): string {
  switch (certType) {
    case CertificationType.CloudPractitioner:
      return cloud_practitioner;
    case CertificationType.DeveloperAssociate:
      return developer_associate;
    case CertificationType.SysOpsAssociate:
      return sysops_associate;
    case CertificationType.SAAssociate:
      return sa_associate;
    case CertificationType.SAPro:
      return sa_pro;
    case CertificationType.SecuritySpecialty:
      return security_specialty;
    default:
      return "";
  }
}

const Certifications: React.FC<{ certifications: CertificationType[] }> = ({ certifications }) => {
  return (
    <div className="certs-container">
      <div className="certs-bar">Certifications</div>
      <div className="certs">
        {certifications.map((certType, index) => {
          return <Certification key={`${certType}${index}`} certType={certType} />;
        })}
      </div>

    </div>
  )
}

export default Certifications;

export function Certification({ certType }: { certType: CertificationType }) {
  var certImage = getCertificationImage(certType);
  return (
    <img className="cert-image-dimensions" alt="" src={certImage} />
  );
}